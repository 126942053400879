import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TacticalImg from "../components/playerImage"
import AffPes from "../components/pes2021cta"

const bestPlayers = () => {
  return (
    <Layout>
      <SEO
        title="Best Bronze Ball Players in PES 2021"
        description="Find out who the best Bronze Ball players are in PES 2021 myClub."
      />
      <div className="container">
        <h1>The best Bronze Ball Players in PES 2021</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/best-players-ball-type/">Best Players</Link>
          </li>
          <li>Bronze Ball Players</li>
        </ul>
        <p>
          Every year, finding out who the best Bronze Ball players are is a fun
          process. Their contracts are cheaper to renew and some of them can
          actually become great assets when fully trained.
        </p>
        <p>
          Most of these players will be very cheap to scout and half of them can
          be obtained by simply combining free scouts. The downside is that
          their conditioning isn't usually as reliable as top players (so you'll
          never know which kind of arrow they will display before a game).
        </p>
        <p>
          In this list, I always talk about{" "}
          <Link to="/player-attributes/">Player Attributes</Link>,{" "}
          <Link to="/player-skills/">Player Skills</Link>, and{" "}
          <Link to="/playing-styles/">Playing Styles</Link>, so have a look at
          those resources if something is unclear.
        </p>
      </div>
      <AffPes />
      <div className="container">
        <h3 className="bullet">I. Meslier (Leeds United)</h3>
        <TacticalImg
          src={`2021/players/i-meslier.jpg`}
          alt={`I. Meslier in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>GK
          <br />
          <strong>OVR / Max: </strong>74 / 85
          <br />
          <strong>Playing Style: </strong>Offensive Goalkeeper
          <br />
        </p>
        <p>
          A very young Goalkeeper with some good overall goalkeeping skills and
          2 of the most wanted skills for this position: "Low Punt" and "Long
          Throw".
        </p>
        <h3 className="bullet">Nuno Mendes (Sporting Lisbon)</h3>
        <TacticalImg
          src={`2021/players/nuno-mendes.jpg`}
          alt={`Nuno Mendes in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>LB
          <br />
          <strong>OVR / Max: </strong>70 / 89
          <br />
          <strong>Playing Style: </strong>Offensive Full-back
          <br />
        </p>
        <p>
          Sporting has a very promising Left-Back with outstanding Speed and
          Stamina for a Bronze Ball player. His "Pinpoint Crossing" is also a
          great skill to keep in mind.
        </p>
        <h3 className="bullet">Vadim Karpov (CSKA Moscow)</h3>
        <TacticalImg
          src={`2021/players/v-karpov.jpg`}
          alt={`Vadim Karpov in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>CB
          <br />
          <strong>OVR / Max: </strong>72 / 89
          <br />
          <strong>Playing Style: </strong>Build Up
          <br />
        </p>
        <p>
          A super aggressive Centre-Back, with good overall physical stats and
          the useful "Man Marking" skill.
        </p>
        <p>
          <strong>Alternative: </strong> have a look at T. Kouassi from FC
          Bayern Munich, another physical, promising CB with good overall
          defending stats and slightly better conditioning (5/8).
        </p>
        <h3 className="bullet">Francisco Javier Montero (Beşiktaş)</h3>
        <TacticalImg
          src={`2021/players/javi-montero.jpg`}
          alt={`Francisco Javier Montero in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>CB
          <br />
          <strong>OVR / Max: </strong>73 / 85
          <br />
          <strong>Playing Style: </strong>Build Up
          <br />
        </p>
        <p>
          Another competent Centre-Back with glasses like the dutch legend Edgar
          Davids. Montero is a quick defender with some good overall defending
          stats and decent conditioning (5/8).
        </p>
        <p>You can get him with free scouts by combining the following:</p>
        <ul>
          <li>Turkey / CB / Acceleration (100% chance)</li>
          <li>Turkey /Acceleration / 185cm or more (50% chance)</li>
        </ul>
        <h3 className="bullet">Ryan Gravenberch (Ajax)</h3>
        <TacticalImg
          src={`2021/players/r-gravenberch.jpg`}
          alt={`Ryan Gravenberch in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>CMF
          <br />
          <strong>OVR / Max: </strong>72 / 88
          <br />
          <strong>Playing Style: </strong>Box-to-Box
          <br />
        </p>
        <p>
          Ajax has a great history of young prospects, and Gravenberch looks
          like one of the best Bronze Ball players in the game.
        </p>
        <p>
          When fully trained, you get a very complete Midfielder, who excels in
          speed and dribbling. The "Double Touch" is, in my opinion, the most
          important dribbling skill, and "One-touch Pass" is also very important
          for a midfield player.
        </p>
        <p>
          If you combine 3 free scouts - "Dutch League", "Tight Possession" and
          "1.85cm or more" - you may get him (50% chance).
        </p>
        <h3 className="bullet">Rayan Cherki (Olympique Lyonnais)</h3>
        <TacticalImg
          src={`2021/players/r-cherki.jpg`}
          alt={`Rayan Cherki in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>AMF
          <br />
          <strong>OVR / Max: </strong>72 / 91
          <br />
          <strong>Playing Style: </strong>Creative Playmaker
          <br />
        </p>
        <p>
          Cherki is a superstar in the making if you're willing to train him all
          the way to the max, with great dribbling stats and speed. The only
          thing that may let you down is his Conditioning (4/8).
        </p>
        <p>
          With a perfect 4/4 weak foot and a wide range of dribbling, passing
          and shooting skills, you get a very complete Bronze Ball playmaker who
          can rival with many established black balls.
        </p>
        <h3 className="bullet">Diego Lainez (Sevilla)</h3>
        <TacticalImg
          src={`2021/players/d-lainez.jpg`}
          alt={`Diego Lainez in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>LMF
          <br />
          <strong>OVR / Max: </strong>74 / 88
          <br />
          <strong>Playing Style: </strong>Prolific Winger
          <br />
        </p>
        <p>
          This youngster from Sevilla excels at Speed and Dribbling when fully
          trained, and can play on both wings (both as LMF/RMF or LWF/RWF).
          However, pay attention to his weak foot; if you use him on the right,
          cut inside to shoot, just like Robben!
        </p>
        <p>
          Diego Lainez also has a very complete set of dribbling tricks,
          including my favorite skill for wingers: the "Double Touch".
        </p>
        <p>
          You can get Diego with 50% chance by combining these 3 scouts with an
          average of 2 stars: "Spanish League" + "N/S America" or "Balance" +
          "LMF".
        </p>
        <p>
          <strong>Alternative: </strong>H. Elliott (Liverpool) is even faster
          and better at dribbling, but has less skills and can only play on the
          right wing.
        </p>
        <h3 className="bullet">Florian Wirtz (Bayer Leverkusen)</h3>
        <TacticalImg
          src={`2021/players/f-wirtz.jpg`}
          alt={`Florian Wirtz in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>RWF
          <br />
          <strong>OVR / Max: </strong>73 / 91
          <br />
          <strong>Playing Style: </strong>Roaming Flank
          <br />
        </p>
        <p>
          A very complete winger: good offensive awareness, great dribbling
          attributes and even better pace. His "Double Touch" skill is something
          I always look for, so Wirtz is someone to keep in mind.
        </p>
        <p>
          You can get this player by combining 3 scouts (33% chance): Other
          (Europe) / RWF / Tight Possession.
        </p>
        <p>
          <strong>Alternative: </strong>have a look at Z. Davitashvili (Rotor
          Volvograd) - very similar stats, and same playing style.
        </p>
        <h3 className="bullet">Mohamed Daramy (FC Copenhagen)</h3>
        <TacticalImg
          src={`2021/players/m-daramy.jpg`}
          alt={`Mohamed Daramy in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>CF
          <br />
          <strong>OVR / Max: </strong>72 / 90
          <br />
          <strong>Playing Style: </strong>Goal Poacher
          <br />
        </p>
        <p>
          Daramy has some good dribbling attributes and very respectful
          finishing, but where he stands out is his speed (95+ on both Speed and
          Acceleration when fully trained).
        </p>
        <p>
          He has some nice dribbling skills, such as the "Double Touch", "Flip
          Flap" and "Scissors Feint", along with "Acrobatic Finishing", so he
          can add some flair to your game as well.
        </p>
        <p>
          <strong>Alternative: </strong>have a look at Marcos Paulo, featured in
          my list last year. A very decent striker, which you can easily train
          by combining any of these scouts (all 100% chance):
        </p>
        <ul>
          <li>Brazilian League / Europe / U-24</li>
          <li>Brazilian League / Europe / Finishing</li>
          <li>CF / U-24 / Low Pass</li>
          <li>CF / Brazilian League / Low Pass</li>
          <li>CF / Brazilian League / Europe</li>
        </ul>
        <h3 className="bullet">Joshua Zirkzee (FC Bayern Munich)</h3>
        <TacticalImg
          src={`2021/players/j-zirkzee.jpg`}
          alt={`Joshua Zirkzee in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>CF
          <br />
          <strong>OVR / Max: </strong>73 / 88
          <br />
          <strong>Playing Style: </strong>Goal Poacher
          <br />
        </p>
        <p>
          A promising striker from the Netherlands, Zirkzee has some good
          overall offensive stats, making him a very complete striker.
        </p>
        <p>
          He has some very good shooting skills, such as "First-time Shot",
          "Rising Shots" and "Chip Shot Control", and has the "Super-Sub" skill,
          making him a good choice to come off the bench.
        </p>
        <p>
          You can get Zirkzee by combining the following scouts (make sure you
          average only 2 stars): Other (Europe) / Acceleration / 1.85cm or more.
        </p>
        <p>
          <strong>Alternative: </strong>Fábio Silva (Wolverhampton) is a similar
          striker, but with the "Fox in the Box" playing style.
        </p>
      </div>
    </Layout>
  )
}

export default bestPlayers
